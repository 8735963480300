import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Intro from "pages/Intro";
import Login from "pages/Login";
import { Fragment } from "react";
import Survey from "pages/Survey";
import AuthNotRequired from "pages/AuthNotRequired";
import AuthRequired from "pages/AuthRequired";
import { Thanks } from "pages/Thanks";
import Detail from "pages/Detail";

export const router = createBrowserRouter([
  {
    path: "/",
    children: [
      {
        path: "",
        element: <Navigate to="/intro" />,
      },
      {
        path: "intro",
        element: <Intro />,
      },
      {
        path: "detail",
        element: <Detail />,
      },
      {
        path: "login",
        element: <AuthNotRequired />,
        children: [
          {
            path: "",
            element: <Login />,
          },
        ],
      },
      {
        path: "u",
        element: <AuthRequired />,
        children: [
          {
            path: "survey",
            element: <Survey />,
          },
          {
            path: "thanks",
            element: <Thanks />,
          },
        ],
      },
    ],
  },
]);
