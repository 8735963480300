import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ThemeProvider, createTheme } from "@mui/material";
import { Toaster } from "react-hot-toast";

const theme = createTheme({
  typography: {
    fontFamily: ["Pretendard"],
  },
  pallette: {
    primary: {
      main: "#1d3557",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
      <Toaster position="bottom-center" />
    </ThemeProvider>
  </React.StrictMode>
);
