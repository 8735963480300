import { Stack, Card, Typography, Button, Divider } from "@mui/material";
import { router } from "Router";
import { useEffect, useState } from "react";
import { db, auth } from "cloud/firebase";
import {
  collection,
  query,
  where,
  getCountFromServer,
} from "firebase/firestore";

export function Thanks() {
  const [count, setCount] = useState(null);
  useEffect(() => {
    async function fetchCount() {
      const coll = collection(db, "survey");
      const uid = await auth.currentUser.uid;
      const q = query(coll, where("user", "==", uid));
      const snapshot = await getCountFromServer(q);
      setCount(snapshot.data().count);
    }
    fetchCount();
  }, []);
  return (
    <Stack sx={{ p: 2 }} spacing={2}>
      <Card sx={{ backgroundColor: "#1d3557", color: "white", p: 2 }}>
        <Typography variant="h4" fontFamily="GangwonState">
          감사합니다!
        </Typography>
        {count === null ? (
          <Typography>데이터를 불러오는 중입니다...</Typography>
        ) : (
          <Typography>지금까지 {count}회 참여하셨습니다.</Typography>
        )}
        <Card sx={{ p: 1, mt: 2 }}>
          <Stack spacing={2}>
            <Typography>
              연구에 기여해주셔서 감사합니다.
              <br />
              <br />
              시간이 괜찮으시다면,
              <br />몇 번 더 참여해주실 수 있으실까요?
            </Typography>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#e63946" }}
              onClick={() => router.navigate("/u/survey")}
            >
              다시 참여하기
            </Button>
          </Stack>
        </Card>
      </Card>
    </Stack>
  );
}
