export const vowels = ["ㅏ", "ㅓ", "ㅜ", "ㅗ", "ㅐ", "ㅔ", "ㅡ", "ㅣ"];

export const files = [
  "10_1.wav",
  "10_10.wav",
  "10_2.wav",
  "10_3.wav",
  "10_4.wav",
  "10_5.wav",
  "10_6.wav",
  "10_7.wav",
  "10_8.wav",
  "10_9.wav",
  "1_1.wav",
  "1_10.wav",
  "1_2.wav",
  "1_3.wav",
  "1_4.wav",
  "1_5.wav",
  "1_6.wav",
  "1_7.wav",
  "1_8.wav",
  "1_9.wav",
  "2_1.wav",
  "2_10.wav",
  "2_2.wav",
  "2_3.wav",
  "2_4.wav",
  "2_5.wav",
  "2_6.wav",
  "2_7.wav",
  "2_8.wav",
  "2_9.wav",
  "3_1.wav",
  "3_10.wav",
  "3_2.wav",
  "3_3.wav",
  "3_4.wav",
  "3_5.wav",
  "3_6.wav",
  "3_7.wav",
  "3_8.wav",
  "3_9.wav",
  "4_1.wav",
  "4_10.wav",
  "4_2.wav",
  "4_3.wav",
  "4_4.wav",
  "4_5.wav",
  "4_6.wav",
  "4_7.wav",
  "4_8.wav",
  "4_9.wav",
  "5_1.wav",
  "5_10.wav",
  "5_2.wav",
  "5_3.wav",
  "5_4.wav",
  "5_5.wav",
  "5_6.wav",
  "5_7.wav",
  "5_8.wav",
  "5_9.wav",
  "6_1.wav",
  "6_10.wav",
  "6_2.wav",
  "6_3.wav",
  "6_4.wav",
  "6_5.wav",
  "6_6.wav",
  "6_7.wav",
  "6_8.wav",
  "6_9.wav",
  "7_1.wav",
  "7_10.wav",
  "7_2.wav",
  "7_3.wav",
  "7_4.wav",
  "7_5.wav",
  "7_6.wav",
  "7_7.wav",
  "7_8.wav",
  "7_9.wav",
  "8_1.wav",
  "8_10.wav",
  "8_2.wav",
  "8_3.wav",
  "8_4.wav",
  "8_5.wav",
  "8_6.wav",
  "8_7.wav",
  "8_8.wav",
  "8_9.wav",
  "9_1.wav",
  "9_10.wav",
  "9_2.wav",
  "9_3.wav",
  "9_4.wav",
  "9_5.wav",
  "9_6.wav",
  "9_7.wav",
  "9_8.wav",
  "9_9.wav",
];
