import { Navigate, Outlet } from "react-router-dom";
import useAuth from "hooks/useAuth";
import { Stack, Typography } from "@mui/material";
import { Fragment } from "react";
import { auth } from "cloud/firebase";
import { signOut } from "firebase/auth";
import { Card, Button } from "@mui/material";
import { router } from "Router";
import { toast } from "react-hot-toast";

export default function AuthRequired() {
  const user = useAuth();

  if (user === undefined) {
    return (
      <Typography>
        사용자를 검사하는 중입니다... 조금만 기다려 주세요...
      </Typography>
    );
  } else if (user === null) {
    toast.error("로그인이 필요합니다!");
    return <Navigate to="/login" replace />;
  } else {
    return (
      <Fragment>
        <Outlet />
        <Card sx={{ mx: 2, p: 1 }}>
          <Stack direction="row" spacing={1}>
            <Button
              onClick={() => {
                signOut(auth);
                toast.success("로그아웃했습니다!");
              }}
              variant="outlined"
            >
              로그아웃
            </Button>
            <Button
              onClick={() => router.navigate("/detail")}
              variant="outlined"
            >
              설명 다시 보기
            </Button>
          </Stack>
        </Card>
      </Fragment>
    );
  }
}
