import {
  Stack,
  Card,
  Typography,
  Divider,
  ToggleButtonGroup,
  ToggleButton,
  Button,
} from "@mui/material";
import { router } from "Router";
import { vowels } from "constants";

export default function Detail() {
  return (
    <Stack sx={{ p: 2 }} spacing={2}>
      <Card sx={{ backgroundColor: "#1d3557", color: "white", p: 2 }}>
        <Typography variant="h5" fontFamily="GangwonState">
          모음 인지 실험
        </Typography>
        <Typography fontFamily="GangwonState">
          Vowel perception experiment
        </Typography>
      </Card>
      <Card sx={{ p: 2 }}>
        <Stack spacing={2}>
          <Stack>
            <Typography variant="h5" fontWeight="bold">
              조사 과정
            </Typography>
            <Divider />
          </Stack>
          <Stack>
            <Typography variant="h6" fontWeight="bold">
              1. 음성 듣기
            </Typography>
            <Typography>
              짧은 음성을 한 번 들려드립니다.
              <br />
              여러 번 다시 들을 수 있습니다.
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="h6" fontWeight="bold">
              2. 유사한 모음 선택
            </Typography>
            <Typography>
              들은 음성과 가장 비슷한 모음을 아래 중 선택합니다.
            </Typography>

            <ToggleButtonGroup
              color="primary"
              exclusive
              aria-label="text alignment"
            >
              {vowels.map((vowel) => (
                <ToggleButton value={vowel}>{vowel}</ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Stack>
          <Stack>
            <Typography variant="h6" fontWeight="bold">
              3. 결과 제출
            </Typography>
            <Typography>설문이 종료됩니다.</Typography>
          </Stack>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#1d3557" }}
            onClick={() => router.navigate("/u/survey")}
          >
            시작하기!
          </Button>
        </Stack>
      </Card>
    </Stack>
  );
}
