import {
  Card,
  Stack,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Box,
  Button,
  StepContent,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import useAuth from "hooks/useAuth";
import { pushDb } from "cloud/pushDb";
import { toast } from "react-hot-toast";
import { files, vowels } from "constants";
import { storage } from "cloud/firebase";

import { ref, getBlob } from "firebase/storage";

import React, { useState } from "react";
import { router } from "Router";

function getRandomString(strings) {
  const randomIndex = Math.floor(Math.random() * strings.length);
  return strings[randomIndex];
}

export default function Survey() {
  const user = useAuth();
  const [audioUrl, setAudioUrl] = React.useState(null);
  const [targetFileName, setTargetFileName] = React.useState(null);
  const [iterate, setIterate] = React.useState(3);
  React.useEffect(() => {
    setTargetFileName(getRandomString(files));
  }, []);

  React.useEffect(() => {
    if (targetFileName !== null) {
      async function fetchAudio() {
        try {
          const targetRef = ref(storage, targetFileName);

          const blob = await getBlob(targetRef);
          const objectUrl = URL.createObjectURL(blob);
          setAudioUrl(objectUrl);
        } catch (error) {
          console.error("Failed to fetch audio:", error);
        }
      }

      fetchAudio();

      // Cleanup the object URL to free up memory
      return () => {
        if (audioUrl) {
          URL.revokeObjectURL(audioUrl);
        }
      };
    }
  }, [targetFileName]);

  const handlePlay = () => {
    const audio = new Audio(audioUrl);
    setActiveStep(1);

    audio.addEventListener("ended", () => {
      setActiveStep(2);
    });

    audio.play();
  };

  const [selection, setSelection] = useState(null);
  const handleChoose = (event, newValue) => {
    setSelection(newValue);
  };

  const [isUploading, setIsUploading] = React.useState(false);
  const handleSubmit = async () => {
    const toastId = toast.loading("제출 중...");
    setIsUploading(true);
    await pushDb({
      user: user.uid,
      audio: targetFileName,
      answer: selection,
      at: new Date().toISOString(),
    });

    toast.success("제출 완료!", { id: toastId });
    router.navigate("/u/thanks");
  };

  const [activeStep, setActiveStep] = React.useState(0);
  return (
    <Stack sx={{ p: 2 }} spacing={2}>
      <Card sx={{ backgroundColor: "#1d3557", color: "white", p: 2 }}>
        <Typography variant="h5" fontFamily="GangwonState">
          모음 인지 실험
        </Typography>
        <Typography fontFamily="GangwonState">
          Vowel perception experiment
        </Typography>
      </Card>
      <Card sx={{ p: 2 }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step>
            <StepLabel>확인 대기</StepLabel>
            <StepContent>
              <Typography>
                음성은 여러 번 다시 들을 수 있습니다. 준비되면 재생버튼을
                눌러주세요!
              </Typography>

              {/* <ToggleButtonGroup
                color="primary"
                size="small"
                value={iterate}
                exclusive
                onChange={(event, newValue) => {
                  setIterate(newValue);
                }}
                aria-label="text alignment"
              >
                <ToggleButton value={1}>1</ToggleButton>
                <ToggleButton value={2}>2</ToggleButton>
                <ToggleButton value={3}>3</ToggleButton>
                <ToggleButton value={4}>4</ToggleButton>
                <ToggleButton value={5}>5</ToggleButton>
              </ToggleButtonGroup> */}
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    disabled={!audioUrl}
                    onClick={handlePlay}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {audioUrl ? "재생!" : "로딩 중..."}
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          <Step>
            <StepLabel>음성 재생</StepLabel>
            <StepContent>
              <Typography>음성이 재생되고 있습니다!</Typography>
            </StepContent>
          </Step>
          <Step>
            <StepLabel>모음 선택</StepLabel>
            <StepContent>
              <Typography>
                방금 들은 음성과 가장 가깝다고 생각하는 모음을 선택하세요.
              </Typography>
              <ToggleButtonGroup
                color="primary"
                value={selection}
                exclusive
                onChange={handleChoose}
                aria-label="text alignment"
              >
                {vowels.map((vowel) => (
                  <ToggleButton value={vowel}>{vowel}</ToggleButton>
                ))}
              </ToggleButtonGroup>
              <Box sx={{ my: 2 }}>
                <Stack direction="row" spacing={1}>
                  <Button
                    variant="outlined"
                    onClick={handlePlay}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    다시 듣기
                  </Button>
                  <Button
                    variant="contained"
                    disabled={selection === null || isUploading}
                    onClick={handleSubmit}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    확인
                  </Button>
                </Stack>
              </Box>
            </StepContent>
          </Step>
        </Stepper>
      </Card>
    </Stack>
  );
}
