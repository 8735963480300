import {
  Stack,
  Card,
  Typography,
  TextField,
  Button,
  Modal,
  Box,
  Divider,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { router } from "Router";
import { auth, db } from "cloud/firebase";
import { setDoc, doc } from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { toast } from "react-hot-toast";
import { useState } from "react";

export default function Login() {
  const [isEmailSignUpOpen, setIsEmailSignUpOpen] = useState(false);
  const handleEmailSignUpButtonClick = async () => {
    if (name.length + phone.length + email.length + password.length < 1) {
      toast.error("모든 정보를 입력해주세요!");
      return;
    }

    if (!privacy) {
      toast.error("개인정보 수집/활용 동의가 필요합니다!");
    }

    const toastId = toast.loading("인증 정보 등록 중...");

    try {
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      toast.loading("사용자 정보 등록 중...", { id: toastId });

      await setDoc(doc(db, "user", user.uid), {
        name,
        email,
        phone,
      });

      toast.success("회원가입 성공!", { id: toastId });
    } catch (error) {
      const errorMessage = error.message;
      toast.error(`회원가입 실패 - ${errorMessage}`, {
        id: toastId,
        duration: 100000,
      });
    }
  };

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const [privacy, setPrivacy] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleLogin = async () => {
    const toastId = toast.loading("로그인 중...");
    try {
      await signInWithEmailAndPassword(auth, email, password);
      toast.success("로그인 성공!", { id: toastId });
      router.navigate("/u/survey");
    } catch {
      toast.error("로그인 실패! 정보를 다시 확인하세요!", { id: toastId });
    }
  };

  return (
    <Stack sx={{ p: 2 }} spacing={2}>
      <Card sx={{ backgroundColor: "#1D3557", color: "white", p: 2 }}>
        <Stack spacing={1}>
          <Typography variant="h4" fontFamily="GangwonState">
            로그인
          </Typography>
          <Card elevation={0} sx={{ p: 2 }}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleLogin();
              }}
            >
              <Stack spacing={2}>
                <Stack spacing={1}>
                  <TextField
                    label="이메일"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <TextField
                    label="패스워드"
                    value={password}
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </Stack>
                <Button variant="outlined" type="submit">
                  로그인
                </Button>
              </Stack>
            </form>
          </Card>
        </Stack>
      </Card>
      <Card sx={{ backgroundColor: "#E63946", color: "white", p: 2 }}>
        <Stack spacing={1}>
          <Typography variant="h4" fontFamily="GangwonState">
            회원가입
          </Typography>
          <Card elevation={0} sx={{ p: 2 }}>
            <Stack spacing={2}>
              <Button
                sx={{ backgroundColor: "#E63946" }}
                variant="contained"
                size="large"
                onClick={() => setIsEmailSignUpOpen(true)}
              >
                이메일로 회원가입
              </Button>
            </Stack>
          </Card>
        </Stack>
      </Card>
      <Modal
        open={isEmailSignUpOpen}
        onClose={() => setIsEmailSignUpOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            이메일로 회원가입
          </Typography>
          <div style={{ height: 15 }} />

          <Stack spacing={2}>
            <Stack spacing={1}>
              <TextField
                label="이름"
                value={name}
                type="string"
                onChange={(e) => setName(e.target.value)}
                required
              />
              <TextField
                label="전화번호"
                value={phone}
                type="tel"
                onChange={(e) => setPhone(e.target.value)}
                required
              />
              <Divider />
              <TextField
                label="이메일"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <TextField
                label="패스워드"
                value={password}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <FormControlLabel
                value={privacy}
                required
                control={
                  <Checkbox
                    onChange={(event) => {
                      setPrivacy(event.target.checked);
                    }}
                  />
                }
                label="개인정보 수집/활용 동의"
              />
            </Stack>
            <Button onClick={handleEmailSignUpButtonClick} variant="contained">
              회원가입
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Stack>
  );
}
