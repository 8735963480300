import * as React from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "cloud/firebase";

export default function useAuth() {
  const [currentUser, setCurrentUser] = React.useState(undefined);
  React.useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
  }, []);
  return currentUser;
}
