import { Paper } from "@mui/material";

import { RouterProvider } from "react-router-dom";
import { router } from "Router";

function App() {
  return (
    <Paper sx={{ height: "100vh", backgroundColor: "#F1FAEE" }}>
      <RouterProvider router={router} />
    </Paper>
  );
}

export default App;
