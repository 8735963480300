import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDsGknHIX5DeZjNaEMMQdx3pNhNnB2gilw",
  authDomain: "vowel-survey.firebaseapp.com",
  databaseURL:
    "https://vowel-survey-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "vowel-survey",
  storageBucket: "vowel-survey.appspot.com",
  messagingSenderId: "139894985630",
  appId: "1:139894985630:web:d029a6df5a536f51c57c8f",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
auth.useDeviceLanguage();

export const googleP = new GoogleAuthProvider();
