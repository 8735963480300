import { Button, Card, Paper, Stack, Typography } from "@mui/material";
import { router } from "Router";

export default function Intro() {
  return (
    <Stack sx={{ p: 3 }} spacing={1}>
      <Card sx={{ p: 2, backgroundColor: "#1D3557", color: "white" }}>
        <Stack direction="column" alignItems="center" spacing={1}>
          <Typography variant="h5" fontWeight="bold" fontFamily="GangwonState">
            모음 인지 실험
          </Typography>
          <Typography
            variant="p"
            fontWeight="bold"
            align="center"
            fontFamily="GangwonState"
          >
            Vowel perception experiment
          </Typography>
        </Stack>
      </Card>
      <Paper sx={{ p: 2 }}>
        <Typography fontFamily="Pretendard">
          인공 소리를 들었을 때 어떤 모음으로 인지되는지에 대해 설문하고자
          합니다!
          <br />
          <br />
          설문에 응해주신 보답으로 소정의 상품을 드릴 예정입니다. 단, 상품을
          위해서 가짜 응답은 삼가해주시길 바랍니다. (연구에 쓰일 설문으로 정확한
          응답이 필요합니다.)
          <br />
          <br />
          설문 응답 횟수는 많을수록, 날짜 간격을 두고 여러 번 응답할수록
          저희에게 도움이 됩니다. 자주 찾아와주세요ㅎㅎ
        </Typography>
      </Paper>
      <Card sx={{ p: 2 }}>
        <Stack spacing={0.5} direction="row">
          <Button
            variant="outlined"
            size="large"
            sx={{ width: "50%" }}
            onClick={() => router.navigate("/detail")}
          >
            설명 읽고 시작
          </Button>
          <Button
            variant="contained"
            size="large"
            sx={{ width: "50%", backgroundColor: "#e63946" }}
            onClick={() => router.navigate("/u/survey")}
          >
            바로 시작
          </Button>
        </Stack>
      </Card>
    </Stack>
  );
}
