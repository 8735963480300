import { Navigate, Outlet } from "react-router-dom";
import useAuth from "hooks/useAuth";
import { Typography } from "@mui/material";

export default function AuthNotRequired() {
  const user = useAuth();

  if (user === undefined) {
    return (
      <Typography>
        사용자를 검사하는 중입니다... 조금만 기다려 주세요...
      </Typography>
    );
  } else if (user === null) {
    return <Outlet />;
  } else {
    return <Navigate to="/u/survey" replace />;
  }
}
